const ALERT_CONSTANTS = {
	SUCCESS: 'ALERT_SUCCESS',
	ERROR: 'ALERT_ERROR',
	SECONDARY: 'ALERT_SECONDARY',
	CLEAR: 'ALERT_CLEAR',
	WARNING: 'ALERT_WARNING',
	INFO: 'ALERT_INFO',
}

const GENERAL_CONSTANTS = {
	SHOW_LOADER: 'SHOW_LOADER',
	HIDE_LOADER: 'HIDE_LOADER',
}

const CHESS_BOARD_CONSTANTS = {
	POSSIBLE_PERMUTATIONS: {
		REQUEST: "POSSIBLE_PERMUTATIONS_REQUEST",
		SUCCESS: "POSSIBLE_PERMUTATIONS_SUCCESS",
		FAILURE: "POSSIBLE_PERMUTATIONS_FAILURE",
	},
	RESET_CHESSBOARD: "RESET_CHESSBOARD"
}

export {
	ALERT_CONSTANTS,
	CHESS_BOARD_CONSTANTS,
	GENERAL_CONSTANTS
}
